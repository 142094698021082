.about .section-title{
    padding-top: 20px;
    margin-top: 15px;
}
.about-grid,
.about-img,
.about-grid .section-title .text{
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}
.about-grid:nth-child(2){
    padding: 50px 0;
    row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
}

@media screen and (min-width: 992px){
    .about-grid:nth-child(1),
    .about-grid:nth-child(3){
        max-width: 100%;
    }

    .about-grid:nth-child(1),
    .about-grid:nth-child(3){
        grid-template-columns: repeat(2, 1fr);
        column-gap: 80px;
    }
    .about-grid:nth-child(3) .section-title{
        text-align: right;
    }
    .about-grid:nth-child(3){
        margin-top: 40px;
    }
}