.projects {
    min-height: 70vh;
    background: linear-gradient(rgba(42,42,46,0.7),40%,rgba(42,42,46,0.85)), url("../../assets/images/project_background.jpg") center/cover no-repeat;
    justify-content: stretch;
}
.projects-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}  
.card {
    width: 500px; /* Add a specific width */
    max-width: 500px; /* Set the maximum width */
    max-height: 100%;
    min-width: 0;
    max-height: 100%;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #00000020;
    border-radius: 0.8rem;
}
.card-img {
    padding: 1.5rem;
    object-fit: cover;
    max-width: 100%;
    height: auto;
    margin-bottom: 0.8rem;
}
.card-footer {
    padding: 1.5rem;
    background-color: transparent;
    border-top: 0;
}
.card-body {
    min-height: 1px;
    padding: 1rem 1.25rem;
}
.card-title {
    line-height: 1.5;
    margin-bottom: 0.5rem;
}
.card.border-0 {
    box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d !important;
    background: hsl(220deg 14% 97%) !important;
    text-align: left;
    background-color: #fff !important;
    margin-top: 12px !important;
    border: 1px solid #eee !important;
    transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    color: #000 !important;
}

@media (min-width: 768px) {
    .projects-content {
      flex-direction: row;
    }
  }
  
  @media (max-width: 767px) {
    .card {
      width: 100%;
      max-width: 100%;
    }
  }