.scrollToTopButton {
    position: fixed;
    bottom: 50px;
    right: 50px;
    height: 50px;
    width: 50px;
    font-size: 35px;
}
.contactMeButton {
    position: fixed;
    bottom: 50px;
    right: 100px;
    height: 50px;
    width: 100px;
    font-size: 35px;
}